import ApiClientManager, { isParty, isProd, isDemo } from "core/apiClient";
import { checkIsMosaicSandboxTeam } from "SandboxModule/utils";

/**
 * Refer to the doc below for details. Make sure always match
 * https://mosaic-ai.atlassian.net/l/cp/8GM6vk3q
 */
export const getCanCreateNewTeam = () => {
  const isUsingNonDefaultRealm = ApiClientManager.getIsUsingNonDefaultRealm();
  const isSubscriptionRealmSelected = ApiClientManager.getIsSubscriptionRealmSelected();

  if (isProd || isParty) {
    return (
      !isUsingNonDefaultRealm ||
      (isUsingNonDefaultRealm && isSubscriptionRealmSelected)
    );
  }

  if (isDemo) return !isUsingNonDefaultRealm;

  return true;
};

/**
 * Remember that can only create new sandbox on Sandbox page
 */
export const getCanCreateNewSandbox = () => {
  const isUsingNonDefaultRealm = ApiClientManager.getIsUsingNonDefaultRealm();
  const isSubscriptionRealmSelected = ApiClientManager.getIsSubscriptionRealmSelected();

  if (isProd) {
    // Not showing sandbox tab on prod anyways
    return !isUsingNonDefaultRealm;
  }

  if (isDemo) return !isUsingNonDefaultRealm;

  if (isParty)
    return (
      !isUsingNonDefaultRealm ||
      (isUsingNonDefaultRealm && !isSubscriptionRealmSelected)
    );

  return true;
};

export const getIsBulkSetUserPasswordEnabled = () => {
  const isUsingNonDefaultRealm = ApiClientManager.getIsUsingNonDefaultRealm();
  const isSubscriptionRealmSelected = ApiClientManager.getIsSubscriptionRealmSelected();
  if (isProd) {
    const isDisabled =
      !isUsingNonDefaultRealm ||
      (isUsingNonDefaultRealm && isSubscriptionRealmSelected);

    return !isDisabled;
  }

  if (isDemo) return isUsingNonDefaultRealm;

  if (isParty) return isUsingNonDefaultRealm && !isSubscriptionRealmSelected;

  // Should only available for sandbox
  return false;
};

export const getIsExportMemberCsvEnabled = () => {
  const isUsingNonDefaultRealm = ApiClientManager.getIsUsingNonDefaultRealm();
  const isSubscriptionRealmSelected = ApiClientManager.getIsSubscriptionRealmSelected();
  if (isProd) {
    const isDisabled =
      !isUsingNonDefaultRealm ||
      (isUsingNonDefaultRealm && isSubscriptionRealmSelected);

    return !isDisabled;
  }

  if (isDemo) return isUsingNonDefaultRealm;

  if (isParty) return isUsingNonDefaultRealm && !isSubscriptionRealmSelected;

  // Should only available for sandbox
  return false;
};

export const getIsDevToolTabEnabled = () => {
  const isUsingNonDefaultRealm = ApiClientManager.getIsUsingNonDefaultRealm();
  const isSubscriptionRealmSelected = ApiClientManager.getIsSubscriptionRealmSelected();
  const isRealmEnabled = ApiClientManager.getIsRealmEnabled();

  if (isDemo) return !isRealmEnabled || !isUsingNonDefaultRealm;

  if (isParty)
    return (
      !isUsingNonDefaultRealm ||
      (isUsingNonDefaultRealm && isSubscriptionRealmSelected)
    );

  // Always show, for cards that uses rails API (budget aggs recal), the selected realm will be used for Url.
  return true;
};

export const getCanSeeSandboxTab = () => {
  // show relevant realms in the current Tenant only
  return isDemo || isParty || isProd;
};

export const getShouldEnableIntegrationServerConnection = () => {
  const isUsingNonDefaultRealm = ApiClientManager.getIsUsingNonDefaultRealm();
  const isSubscriptionRealmSelected = ApiClientManager.getIsSubscriptionRealmSelected();

  if (isProd || isParty || isDemo) {
    /**
     * Spec: We only allow hitting IS when:
     * - Connecting to subscription realm (ie: Not connected to sandbox realm)
     *  OR
     * - Connecting to default realm
     */
    const shouldEnableConnectionToIS =
      isSubscriptionRealmSelected || !isUsingNonDefaultRealm;

    return shouldEnableConnectionToIS;
  }

  // Other env - fetch integration
  return true;
};

export const getShouldFetchAvailableDumpFiles = () => {
  return !isProd;
};

export const getIsTenantEnabled = () => {
  return isParty || isProd;
};

export const getShouldShowSubphaseConversionStatus = () => {
  const isSandboxRealmSelected = ApiClientManager.getIsSandboxRealmSelected();
  const isUsingNonDefaultRealm = ApiClientManager.getIsUsingNonDefaultRealm();
  // when a sandbox realm is selected that is not default realm
  if (isSandboxRealmSelected) {
    return !isUsingNonDefaultRealm;
  }
  return true;
};

export const getIsEditingMemberEmailDisabled = ({
  teamId,
}: {
  teamId: number;
}) => {
  const tenant = ApiClientManager.getTenant();
  if (isProd) {
    const isMosaicSandboxTeam = checkIsMosaicSandboxTeam(teamId);
    return isMosaicSandboxTeam && tenant.tenantName === "try";
  }
  if (isParty) return false;

  return true;
};

import keyBy from "lodash/keyBy";

// Hard coded for now until BE have an endpoint
const csmListByEnv = {
  party: [
    { accountId: 8608, name: "Marissa Walker" },
    { accountId: 87930, name: "Lindsey Ulmer" },
    { accountId: 113126, name: "Hallie Stennes" },
    { accountId: 117433, name: "Jessica Romandetti" },
  ],
  demo: [
    { accountId: 12438, name: "Marissa Walker" },
    { accountId: 14062, name: "Lindsey Ulmer" },
    { accountId: 14392, name: "Hallie Stennes" },
    { accountId: 14689, name: "Jessica Romandetti" },
  ],
  prod: [
    { accountId: 149736, name: "Marissa Walker" },
    { accountId: 158119, name: "Lindsey Ulmer" },
    { accountId: 175652, name: "Hallie Stennes" },
    { accountId: 189191, name: "Jessica Romandetti" },
  ],
  gov: [
    { accountId: 12438, name: "Marissa Walker" },
  ],
};

/**
 * Hard coded to reduce cost dev, but ideally should have an endpoint to provide this list
 */
export const csmList =
  csmListByEnv[process.env.REACT_APP_UI_ENV as keyof typeof csmListByEnv] || [];

export const csmHashById = keyBy(csmList, "accountId");

export const csmHashByName = keyBy(csmList, "name");
